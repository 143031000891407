<div *ngIf="chatbotActive" id="body">
  <!-- Chat Button -->
  <div #chatButton id="chat-button" [ngClass]="{'show': isChatButtonVisible, 'hide': !isChatButtonVisible}"
    (click)="toggleChatBox()" aria-label="Open chat">
    <div class="avatar">
      <i nz-icon nzType="icons:icons-chatbot"></i>
    </div>
  </div>

  <!-- Chat Box -->
  <div class="chat-box"
    [ngClass]="{'hide': !chatbotService.isChatBoxVisible.getValue(), 'show': chatbotService.isChatBoxVisible.getValue()}"
    role="dialog" aria-labelledby="chat-box-header">
    <div class="chat-box-header" id="chat-box-header">
      {{ CHATBOT_NAME }}
      <span class="chat-box-toggle" aria-label="Options">
        <i (click)="toggleChatBox()" nz-icon nzType="minus" nzTheme="outline" aria-hidden="true"></i>
        <i (click)="resetChatBot()" nz-icon nzType="close" nzTheme="outline" aria-hidden="true"></i>
      </span>
    </div>
    <div class="chat-box-body">
      <div class="chat-box-overlay"></div>
      <div class="chat-logs">
        <div *ngFor="let message of messages"
          [ngClass]="{'chat-msg': true, 'self': message.sender === 'user', 'bot': message.sender === 'bot'}">
          <div class="msg-avatar">
            <i *ngIf="message.sender === 'bot'" nz-icon nzType="icons:icons-chatbot"></i>
            <i *ngIf="message.sender === 'user'" nz-icon nzType="icons:icons-account" nzTheme="outline"></i>
          </div>
          <div *ngIf="message.text" class="cm-msg-text" [innerHTML]="message.text"></div>
          <div *ngIf="message.buttons?.length > 0" class="service-buttons">
            <button *ngFor="let button of message.buttons" class="service-button"
              (click)="onButtonSelect(button.id, button.name)">
              {{ button.name }}
            </button>
          </div>
        </div>

        <!-- Loading FAQs Indicator -->
        <div *ngIf="isLoadingFAQs" class="chat-msg bot">
          <div class="msg-avatar">
            <i nz-icon nzType="icons:icons-chatbot"></i>
          </div>
          <div class="cm-msg-text loading-dots">
            <span>{{ 'CHATBOT.ERRORS.LOADING_FAQS' | translate }}</span><span>.</span><span>.</span><span>.</span>
          </div>
        </div>

        <!-- Loading Indicator -->
        <div *ngIf="isLoadingMessage" class="chat-msg bot">
          <div class="msg-avatar">
            <i nz-icon nzType="icons:icons-chatbot"></i>
          </div>
          <div class="cm-msg-text loading-dots">
            <span>.</span><span>.</span><span>.</span>
          </div>
        </div>

      </div>
    </div>
    <div class="chat-input">
      <form (submit)="onSubmit($event)">
        <input type="text" id="chat-input" placeholder="{{ 'CHATBOT.SEND_BUTTON' | translate }}"
          [(ngModel)]="userMessage" name="userMessage" />
        <button type="submit" class="chat-submit" id="chat-submit"
          [disabled]="!chatbotService.getSelectedServiceID() && isInputDisabled">
          <i nz-icon nzType="send" nzTheme="outline"></i>
        </button>
      </form>
    </div>
  </div>
</div>